<template>
  <section id="deployment" ref="deploymentContent">
    <PageTitle :title="$t('shop.deployment.title')" :breadcrumbs="breadcrumbs"></PageTitle>
    
    <div class="wrapper wrapper-content animated fadeInRight">

      <Header></Header>

      <div class="row" v-if="selectedShop && (selectedShop.options.isShopDeploymentAllowed || selectedShop.options.isProductsDeployementAllowed)">
        <div class="col">
          <Validation></Validation>
        </div>
      </div>

      <div class="row" v-if="selectedShop && selectedShop.options.isShopDeploymentAllowed">
        <div class="col">
          <Shop></Shop>
        </div>
      </div>

      <div class="row" v-if="selectedShop && selectedShop.options.isProductsDeployementAllowed">
        <div class="col">
          <Products></Products>
        </div>
      </div>

    </div>
  </section>
</template>


<script lang="ts">
import { Component } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import GenericPage from '@fwk-client/components/mixins/GenericPage.vue';

import PageTitle from '@root/src/client/components/panels/PageTitle.vue';
import { enableIbox, disableIbox} from '@root/src/client/theme/inspinia-jquery'; 

import { useShopAdmin } from '../../composables/useShopAdmin';

import Header from '../panels/Header.vue';

import Shop from '../panels/deployment/Shop.vue';
import Products from '../panels/deployment/Products.vue';
import Validation from '../panels/deployment/Validation.vue';


@Component({
  components: {
    PageTitle,
    Shop,
    Products,
    Header,
    Validation
  }
})
export default class Deployment extends mixins(GenericPage) {

  setup(props:any, context:any) {
    const { shops, selectedShop } = useShopAdmin(props, context);
    return { shops, selectedShop }
  }

  get breadcrumbs() {
    return [{
      label: this.$t('home.dashboard')
    },
    {
      label: this.$t('shop.title')
    }]
  }

  mounted() {
    enableIbox(this.$refs.deploymentContent as HTMLElement);
  }

  beforeDestroy() {
    disableIbox(this.$refs.deploymentContent as HTMLElement);
  }

}
</script>
<template>
  <div class="ibox deployment-products">
    <div class="ibox-title">
      <h2>{{ $t('shop.deployment.products.title') }}</h2>
    </div>
    <div :class="'ibox-content p-md' + (listLoading ? ' sk-loading' : '')">

      <div v-if="listLoading" class="sk-spinner sk-spinner-double-bounce">
          <div class="sk-double-bounce1"></div>
          <div class="sk-double-bounce2"></div>
      </div>

      <p>{{ $t('shop.deployment.products.note') }}</p>

      <form role="form" @submit="onFormSubmit" name="publish-products">
        <button class="btn btn-primary ladda-button publish-products" data-style="zoom-in" type="submit">{{$t('shop.deployment.products.button')}}</button>
      </form>

    </div>
  </div>
</template>

<script lang="ts">
import { Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import * as api from '@fwk-client/utils/api';
import * as Ladda from 'ladda';

import { useShopAdmin } from '../../../composables/useShopAdmin';

export default defineComponent({
    props: {
        
    },
    components: {
    },
    setup(props, context) {
      const app = getApp();
      const $router = useRouter();
      const $store = useStore();
      const { selectedShop, callShopAdmin } = useShopAdmin(props, context);


      const listLoading:Ref<boolean> = ref(false);

      var laddaSubmit:Ladda.LaddaButton|null = null;

      onMounted(() => {
        var submitButton:HTMLButtonElement|null = document.querySelector( 'form[name=publish-products] button.publish-products' );
        laddaSubmit = Ladda.create(submitButton!);
      })

      const onFormSubmit = (evt:Event) => {
        // We prevent submit of the page
        evt.preventDefault();

        laddaSubmit!.start();

        var path = '/shop/'+selectedShop.value.shop._id+'/deployment/publish-products';
        
        callShopAdmin(path).then((response:any) => {
          if(response.published && response.categories) {  
            // We emit event
            context.emit('products-published', response.categories);
          }

          laddaSubmit!.stop();
        });
        
      }

        return { 
          listLoading,
          onFormSubmit
        }
    }
})
</script>
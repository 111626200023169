<template>
  <div class="ibox deployment-customization">
    <div class="ibox-title">
      <h2>{{ $t('shop.deployment.validation.title') }}</h2>
    </div>
    <div :class="'ibox-content p-md' + (listLoading ? ' sk-loading' : '')">

      <div v-if="listLoading" class="sk-spinner sk-spinner-double-bounce">
          <div class="sk-double-bounce1"></div>
          <div class="sk-double-bounce2"></div>
      </div>

      <p>{{ $t('shop.deployment.validation.note') }}</p>
      <a v-if="shopContentValidationLink" class="btn btn-primary" :href="shopContentValidationLink" target="_blank">{{$t('shop.deployment.validation.button-shop-content')}}</a>
      <a v-if="siteValidationLink" class="btn btn-primary" :href="siteValidationLink" target="_blank">{{$t('shop.deployment.validation.button-site', {'domain': siteTopLevelDomain})}}</a>

    </div>
  </div>
</template>

<script lang="ts">
import { Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

import { useVigneronOnlineAdmin } from '../../../../vigneron-online/composables/useVigneronOnlineAdmin';
import { useSiteAdmin } from '../../../../cms/composables/useSiteAdmin';
import { useShopAdmin } from '../../../composables/useShopAdmin';

export default defineComponent({
    props: {
        
    },
    components: {
    },
    setup(props, context) {
      const app = getApp();
      const $router = useRouter();
      const $store = useStore();

      const { selectedShop, selectedShopContent, selectedSite } = useShopAdmin(props, context);
      const { getShopContentValidationLink } = useVigneronOnlineAdmin(props, context);
      const { getSiteValidationLink } = useSiteAdmin(props, context);

      const listLoading:Ref<boolean> = ref(false);

      const shopContentValidationLink = computed(() => {
        return getShopContentValidationLink(selectedShopContent.value);
      }) 

      const siteTopLevelDomain = computed(() => {
        return selectedSite.value ? selectedSite.value.topLevelDomain : undefined;
      })

      const siteValidationLink = computed(() => {
        return getSiteValidationLink(selectedSite.value);
      });

      return { 
        listLoading,
        shopContentValidationLink,
        siteTopLevelDomain,
        siteValidationLink
      }
    }
})
</script>